var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "tit-wrap--small"
  }, [_c('div', {
    staticClass: "pb-8px pb-md-12px"
  }, [_c('v-row', {
    staticClass: "row--sm",
    attrs: {
      "align": "center"
    }
  }, [_c('div', {
    staticClass: "col col-auto"
  }, [this.$slots['titleContents'] ? _c('div', {
    staticClass: "txt txt--xs txt--dark font-weight-medium"
  }, [_vm._t("titleContents")], 2) : _c('h3', {
    staticClass: "txt txt--xs txt--dark font-weight-medium"
  }, [_vm._v(_vm._s(_vm.title))])]), this.$slots['default'] ? _c('v-spacer') : _vm._e(), this.$slots['default'] ? _c('div', {
    staticClass: "col col-auto"
  }, [_vm._t("default")], 2) : _vm._e()], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }