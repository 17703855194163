var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "row--xs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    class: _vm.class__col
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "placeholder": "주민등록번호 앞자리"
    },
    on: {
      "input": _vm.formatNumber1
    },
    model: {
      value: _vm.number1,
      callback: function ($$v) {
        _vm.number1 = $$v;
      },
      expression: "number1"
    }
  }, 'v-text-field', Object.assign({
    label: _vm.label
  }, _vm.$attrs), false))], 1), _c('v-col', {
    class: _vm.class__col
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "placeholder": "주민등록번호 뒷자리"
    },
    on: {
      "input": _vm.formatNumber2
    },
    model: {
      value: _vm.number2,
      callback: function ($$v) {
        _vm.number2 = $$v;
      },
      expression: "number2"
    }
  }, 'v-text-field', _vm.$attrs, false))], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }